import React from "react"
import Layout from "../../components/common/layout"
import Main from "../../components/common/main"
import SEO from "../../components/common/seo"

const intro = (
  <>
    <div className="vb-150" />
    <div className="container line-container d-flex">
      <div className="col px-0">
        <div className="row">
          <div className="col-lg-8">
            <h1 className="display-2  text-white">
              Training
              <span>Angular, React and Node.js</span>
            </h1>
          </div>
        </div>
      </div>
    </div>
    <div className="pt-100" />
  </>
)

const body = (
  <section className="section bg-secondary">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row row-grid">
            <div className="col-lg-12">
                <div className="row row-grid align-items-center">
                    <div className="col-md-12 order-md-1">
                      <div className="pr-md-5">
                        <h1>Coming soon... 🚧</h1>
                      </div>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

const Training = props => {
  return (
    <Layout>
      <SEO title="Training" />
      <Main lineColor="line-dark">{intro}</Main>
      {body}
    </Layout>
  )
}

Training.propTypes = {}

export default Training
